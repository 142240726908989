import { mapGetters } from 'vuex';

const carFeatureMixin = {
	computed: {
		...mapGetters(['showCredit']),
	},
	methods: {
		mapFeatures(data) {
			return [
				{
					icon: 'fas fa-calendar-alt',
					text: 'Yıl',
					value: data.year,
					show: true,
				},

				{
					icon: 'fas fa-tachometer-alt',
					text: 'KM',
					value: this.$root.$options.filters.tl(data.kilometer, {
						symbol: '',
						avoidEmptyDecimals: '',
					}),
					show: true,
				},
				{
					icon: 'fas fa-gas-pump',
					text: 'Yakıt',
					value: data.fuelType,
					show: true,
				},
				{
					icon: 'fas fa-gear',
					text: 'Vites',
					value: data.transmissonType,
					show: true,
				},
				{
					icon: 'fas fa-ribbon',
					text: 'Garanti',
					value: 'Garanti',
					show: data.hasWarranty,
				},
			];
		},
		title(car) {
			return `${car.brand} ${car.model} ${car.version}`;
		},
	},
};

export default carFeatureMixin;
