import { mapGetters, mapActions } from 'vuex';

import { TOGGLE_FAVORITE_CAR } from '@/store/modules/listing.module';

const carFavoriteMixin = {
	computed: {
		...mapGetters(['favoriteCars']),
	},
	methods: {
		...mapActions({
			toggleFavorite: TOGGLE_FAVORITE_CAR,
		}),
		addToFavorites(id) {
			this.toggleFavorite(id);
		},
		isFavorite(id) {
			return this.favoriteCars.includes(id);
		},
	},
};

export default carFavoriteMixin;
